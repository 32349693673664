import { Link, useNavigate } from "@remix-run/react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useAddToCartMutation } from "~/old-app/api/cart";
import { useToggleFavoriteMutation } from "~/old-app/api/favorite";
import {
  useAddToInfluencerCollectionMutation,
  useRemoveFromInfluencerCollectionMutation,
} from "~/old-app/api/influencer";
import { FavFilledIcon, FavIcon } from "~/old-app/assets/icons";
import { DefaultImage } from "~/old-app/constants";
import { useCurrency } from "~/old-app/hooks/useCurrency";
import { dispatch } from "~/old-app/redux";
import { useAuthSlice } from "~/old-app/redux/auth-slice";
import { CartActions } from "~/old-app/redux/cart-slice";
import { cls } from "~/old-app/utils/helpers/cls";
import StarRating from "../Rating";
import { ProductTags } from "./tags";

const CardProduct = ({
  item: product,
  imgW = "full",
  imgH = "h-52",
  type = "product",
  influencerCode = "",
  size = "lg",
  titleTag = "h3",
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { formatPrice } = useCurrency();
  const { accessToken, isInfluencer } = useAuthSlice();
  const [isFav, setIsFav] = useState(product?.favorite);

  const isLoggedIn = !!accessToken;
  const [toggleFav] = useToggleFavoriteMutation();
  const [addToInfluencerCollection] = useAddToInfluencerCollectionMutation();
  const [removeFromInfluencerCollection] =
    useRemoveFromInfluencerCollectionMutation();
  const hasTags = !!product?.ribbon;
  const isDigitalProduct = !!product?.is_digital;

  const favoriteToggleHandler = () => {
    if (!isLoggedIn) {
      navigate(`/${language}/signin?redirectUrl=${window.location.pathname}`);
    } else {
      toggleFav({
        item_id: product?.id,
        type: "product",
      })
        .unwrap()
        .then((data) => {
          setIsFav((prev) => !prev);
          toast.success(data.message);
        });
    }

    // if (isInfluencer) {
    //   (isFav
    //     ? removeFromInfluencerCollection(product.id)
    //     : addToInfluencerCollection(product.id)
    //   )
    //     .unwrap()
    //     .then((data) => {
    //       toast.success(data.message);
    //     });
    // } else {
    //   toggleFav({
    //     item_id: product.id,
    //     type,
    //   })
    //     .unwrap()
    //     .then((data) => {
    //       toast.success(data.message);
    //       setIsFav(!isFav);
    //     });
    // }
  };

  const primaryImage =
    product?.media?.find((media) => media.is_primary === 1) || {};

  const urlParam = product?.slug || product?.id;

  const [addToCartMutate, { isLoading }] = useAddToCartMutation();
  const navigate = useNavigate();

  const addToCartHandler = () => {
    if (isDigitalProduct) {
      navigate(`/${language}/checkout/digital-product/${urlParam}`);
      return;
    }

    if (!isLoggedIn) {
      dispatch(
        CartActions.addToCart({
          id: product?.id,
          qty: 1,
          order_type: "web",
          influencer_id: influencerCode,
          increaseQty: true,
        })
      );
      return;
    }

    addToCartMutate({
      id: product?.id,
      qty: 1,
      order_type: "web",
      delete_old_cart: false,
      influencer_id: influencerCode,
    })
      .unwrap()
      .then((data) => {
        toast.success(data.message);
      });
  };

  useEffect(() => {
    if (product?.id) {
      setIsFav(product?.favorite);
    }
  }, [product?.id]);

  const TitleComponent = useMemo(() => {
    switch (titleTag) {
      case "h1":
        return "h1";
      case "h2":
        return "h2";
      case "h3":
        return "h3";
      case "h4":
        return "h4";
      case "h5":
        return "h5";
      case "p":
        return "p";
      case "span":
        return "span";
      default:
        return "h3";
    }
  }, [titleTag]);

  return (
    <div className="w-full">
      <div className="relative overflow-hidden group flex flex-col min-w-0 break-words bg-white w-full rounded-lg gap-0 border border-gray-80 ">
        <Link
          to={`/${language}/products/${urlParam}${
            influencerCode ? `?influencer=${influencerCode}` : ""
          }`}
          className={`relative flex items-center justify-center rounded-lg w-full md:w-${imgW} `}
        >
          {/* {product?.ribbon && (
            <span className="absolute top-2 end-2 bg-success-600 rounded-full text-xs inline-block px-2 py-1 text-white">
              {product?.ribbon?.substring(0, 15)}{" "}
              {product?.ribbon?.length > 15 && ".."}
            </span>
          )} */}

          <img
            alt={primaryImage.name}
            src={primaryImage.url ? primaryImage.url : product?.image}
            className={`w-full ${imgH} object-cover rounded-top-lg align-middle`}
            loading="lazy"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = DefaultImage;
            }}
          />
        </Link>
        <div className="absolute top-1 start-1 ">
          <ProductTags product={product} />
        </div>
        <div
          className={cls("p-4  flex flex-col min-h-[8.375rem]", {
            // "p-2": size === "sm",
          })}
        >
          <blockquote>
            <Link to={`/${language}/products/${urlParam}`}>
              <TitleComponent
                className={cls(
                  `font-almarai font-bold text-base text-secondary-950 leading-3 capitalize overflow-hidden line-clamp-2`,
                  {
                    // "text-sm": size === "sm",
                  }
                )}
              >
                {product?.name}
              </TitleComponent>
            </Link>

            <div className="flex gap-2 items-center mb-1">
              {product?.rating_average !== "undefined" && (
                <StarRating
                  size={size}
                  rating={parseFloat(product?.rating_average)}
                />
              )}
              {product?.rating_count !== "undefined" && (
                <span className="text-xxs text-typo-700">
                  ({product?.rating_count})
                </span>
              )}
            </div>
            {/* <div className="text-xs flex gap-3  text-gray-500 leading-3 ">
              {t("global.price")}
            </div> */}
            <div
              className={cls(
                {
                  "text-sm flex gap-0 md:gap-2 leading-3 flex-col md:flex-row":
                    size === "lg",
                },
                {
                  // "text-sm flex gap-1 flex-col": size === "sm",
                }
              )}
            >
              {!!product?.on_sale ? (
                <div
                  className={cls("flex gap-1", {
                    "flex-col": size === "sm",
                  })}
                >
                  <span className="whitespace-nowrap text-sm text-secondary-950 font-semibold  ">
                    {formatPrice(product?.sale_price)}
                  </span>
                  <span className="text-gray-500 line-through text-xs">
                    {formatPrice(product?.price)}
                  </span>
                </div>
              ) : (
                <span className="whitespace-nowrap text-sm text-secondary-950 font-semibold  ">
                  {formatPrice(product?.price)}
                </span>
              )}
            </div>
          </blockquote>
          {isLoggedIn ? (
            <>
              {isFav ? (
                <div className="absolute top-2 end-2 flex items-center justify-end p-1 border border-primary-400 rounded-full bg-white ">
                  <FavFilledIcon
                    onClick={favoriteToggleHandler}
                    className="text-primary-400 cursor-pointer"
                    size={18}
                  />
                </div>
              ) : (
                <div className="absolute top-2 end-2 flex items-center justify-end p-1 border border-primary-400 rounded-full bg-white ">
                  <FavIcon
                    onClick={favoriteToggleHandler}
                    className="text-primary-400 cursor-pointer"
                    size={18}
                  />
                </div>
              )}
            </>
          ) : null}

          {/* <div
            className="absolute h-8 w-8 rounded-full flex items-center justify-center bg-primary-500 text-white bottom-2  end-2 cursor-pointer"
            onClick={addToCartHandler}
          >
            {isLoading ? (
              <div className="flex w-full justify-center items-center py-10">
                <SpinnerIcon
                  className="animate-spin text-2xl text-primary"
                  size={14}
                />
              </div>
            ) : (
              <Plus size={16} />
            )}
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default CardProduct;
