import { Link, useNavigate } from "@remix-run/react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { cls } from "~/old-app/utils/helpers/cls";

export const ProductTags = ({ product, size = "sm", className }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className={cls("flex flex-wrap flex-col gap-1", className)}>
      {product?.labels?.map((label, index) => (
        <span
          className={clsx(
            size === "lg"
              ? "px-2 text-sm py-1 rounded-md bg-primary-100 border-primary-600 text-primary-600 text-center cursor-pointer"
              : "px-2 text-xxs py-1 rounded-sm bg-primary-100 border-primary-600 text-primary-600 text-center cursor-pointer",
            {
              "bg-success-100 border border-success-600 text-success-600":
                label?.color === "green",
              "bg-warning-100 border border-warning-600 text-warning-600":
                label?.color === "yellow",
              "bg-error-100 border border-error-600 text-error-600":
                label?.color === "red",
              "bg-secondary-100 border border-secondary-600 text-secondary-600":
                label?.color === "blue",
            }
          )}
          key={`label-${label?.name}-${index}`}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/${language}/search-results?page=1&tag_ids=${label?.id}`);
          }}
        >
          {label?.title}
        </span>
      ))}

      {product?.tags
        ?.filter((e) => !e.icon)
        ?.map((label, index) => (
          <span
            onClick={(e) => {
              e.stopPropagation();
              navigate(
                `/${language}/search-results?page=1&tag_ids=${label?.id}`
              );
            }}
            className={clsx(
              size === "lg"
                ? "px-2 text-sm py-1 rounded-md  text-center flex gap-3 items-center cursor-pointer"
                : "px-2 text-xxs py-1 rounded-sm  text-center flex gap-2 items-center cursor-pointer"
            )}
            key={`label-${label?.id}-${index}`}
            style={{
              backgroundColor: label?.background_color,
              color: label?.color,
            }}
          >
            <span>{label?.name}</span>
          </span>
        ))}

      {product?.tags
        ?.filter((e) => e.icon)
        ?.map((label, index) => (
          <img
            key={`label-${label?.id}-${index}`}
            src={label?.icon}
            alt={label?.name}
            onClick={(e) => {
              e.stopPropagation();
              navigate(
                `/${language}/search-results?page=1&tag_ids=${label?.id}`
              );
            }}
            className={cls(
              "w-16 h-16 object-cover rounded-full bg-primary-200 border-2 cursor-pointer",
              {
                "w-10 h-10": size === "sm",
              }
            )}
            style={{
              borderColor: label?.color,
            }}
          />
        ))}

      {/* {
        "id": 2,
        "name": "QA",
        "icon": "https://hudhud-dev.s3.eu-west-3.amazonaws.com/public/upload/173884358867a4a5c4eee3f0_35400842.webp",
        "color": "#ffffff",
        "background_color": "#9900ef"
    } */}
    </div>
  );
};
